import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from '@mui/material';

const pages = [{ id: '#gallery', label: 'Галерија' }, { id: '#aboutUs', label: 'За Нас' }, { id: '#contact', label: 'Контакт' }];

const NavBar = () => {
    const matches = useMediaQuery('(max-width:1380px)');
    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return (
        <AppBar position="sticky" style={{ background: '#f5d432' }}>
            <Container maxWidth="100%">
                <Toolbar disableGutters>
                    <Typography
                        variant="h5"
                        noWrap
                        px="80px"
                        component="a"
                        href="/"
                        sx={{
                            position: "absolute",
                            mr: 2,
                            display: { xs: 'none', md: 'flex' },
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'black',
                            textDecoration: 'none',
                        }}
                    >
                        <img src="./logo.png" width={`${matches ? '150px' : '200px'}`} alt="logo" />
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            className="hahahaha"
                            onClick={handleOpenNavMenu}
                            style={{ color: 'black' }}
                        >
                        <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {pages.map((page) => (
                                <a href={page.id} style={{ textDecoration: 'none' }} key={page.id}>
                                    <MenuItem key={page} onClick={handleCloseNavMenu} className="headerItem">
                                        <Typography textAlign="center" color="black">{page.label}</Typography>
                                    </MenuItem>
                                </a>
                            ))}
                        </Menu>
                    </Box>
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href=""
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            color: 'black',
                            textDecoration: 'none',
                        }}
                    >
                        <img src="./logo.png" width="150px" />
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'center' }}>
                        {pages.map((page) => (
                            <a key={page.id} href={page.id} style={{ textDecoration: 'none' }}>
                                <Button
                                    key={page}
                                    className="headerItem"
                                    onClick={handleCloseNavMenu}
                                    sx={{ my: 2, mx: `${matches ? '15px' : '100px'}`, display: 'block' }}
                                    style={{
                                        color: '#5b5b5b',
                                        fontSize: `${matches ? '18px' : '22px'}`,
                                        fontWeight: '700',
                                        fontFamily: 'sans-serif',
                                    }}
                                >
                                    {page.label}
                                </Button>
                            </a>
                        ))}
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
};
export default NavBar;
