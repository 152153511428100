import './App.css';
import Gallery from './Gallery';
import NavBar from './NavBar';
import Divider from '@mui/material/Divider';
import AboutUs from './AboutUs';
import Contact from './Contact';

function App() {
  return (
    <div className="App">
      <div className="App-header">
        <NavBar/>
        <Gallery/>
        <Divider/>
        <AboutUs/>
        <Divider/>
        <Contact/>
      </div>
    </div>
  );
}

export default App;
