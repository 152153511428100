import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { useMediaQuery } from '@mui/material';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
    {
        label: 'Front',
        imgPath:
            './front.jpg',
    },
    {
        label: 'frontShort',
        imgPath:
            './front_short.jpg',
        width: '600px',
    },
    {
        label: 'side',
        imgPath:
            './side.jpg',
    },
    {
        label: 'warehouse1',
        imgPath:
            './warehouse1.jpg',
    },
    {
        label: 'warehouse',
        imgPath:
            './warehouse.jpg',
        width: '600px',
    },
];

function Gallery() {
    const matches = useMediaQuery('(max-width:900px)');
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = images.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    return (
        <Box sx={{ flexGrow: 1, backgroundImage: 'linear-gradient(#f5d432, white)' }} id="gallery">
            <AutoPlaySwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
                interval={5000}
            >
                {images.map((step, index) => (
                    <div key={step.label}>
                        {Math.abs(activeStep - index) <= 5 ? (
                            <Box
                                component="img"
                                sx={{
                                    height: 500,
                                    boxShadow: '0px 2px 100px 0px white',
                                    display: 'block',
                                    margin: '0 auto',
                                    maxWidth: matches ? '100%' : 900,
                                    overflow: 'hidden',
                                    marginBottom: '30px',
                                    width: `${step.width ? step.width : '100%'}`,
                                    borderBottomLeftRadius: '.85rem',
                                    borderBottomRightRadius: '.85rem'
                                }}
                                src={step.imgPath}
                                alt={step.label}
                            />
                        ) : null}
                    </div>
                ))}
            </AutoPlaySwipeableViews>
            <MobileStepper
                steps={maxSteps}
                position="static"
                sx={{ backgroundColor: '#fefbee', color: 'black' }}
                activeStep={activeStep}
                nextButton={
                    <Button
                        size="small"
                        onClick={handleNext}
                        sx={{ color: 'black' }}
                        disabled={activeStep === maxSteps - 1}
                    >
                        {theme.direction === 'rtl' ? (
                            <KeyboardArrowLeft />
                        ) : (
                            <KeyboardArrowRight />
                        )}
                    </Button>
                }
                backButton={
                    <Button size="small" sx={{ color: 'black' }} onClick={handleBack} disabled={activeStep === 0}>
                        {theme.direction === 'rtl' ? (
                            <KeyboardArrowRight />
                        ) : (
                            <KeyboardArrowLeft />
                        )}
                    </Button>
                }
            />
        </Box>
    );
}

export default Gallery;
