import { Box, Typography, useMediaQuery } from '@mui/material';
import MailIcon from '@mui/icons-material/Mail';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
const Contact = () => {
    const matches = useMediaQuery('(max-width:600px)');
    const matchesMobile = useMediaQuery('(max-width:700px)');
    const matchesTablet = useMediaQuery('(max-width:850px)');
    return <Box p={`${matchesMobile ? '40px' : '100px'}`} id="contact" display="flex" justifyContent="center" alignItems="left" gap="50px" flexDirection={`${matchesTablet ? 'column' : 'row'}`}>
        <Box>
            <Typography variant="h4" mb={5} component="h4" color="#5b5b5b" fontWeight="900">Контакт</Typography>
            <Box mb={3}>
                <MailIcon sx={{ verticalAlign: "middle", marginRight: '10px' }} /> Контакт: <a href="mailto:lamela@gmail.com">lamela@gmail.com</a>
            </Box>
            <Box mb={3}>
                <LocalPhoneIcon sx={{ verticalAlign: "middle", marginRight: '10px' }} /> Тел.број: +38970372993
            </Box>
            <Box>
                <LocationOnIcon sx={{ verticalAlign: "middle", marginRight: '10px' }} />Адреса: Борис Кидриџ 96, Тетово 1220
                <br />
                <Box mt={2}>
                    <iframe
                        title="Локација"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d524.0738566732026!2d20.979873986580646!3d42.008657161163555!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1353f157b87e4067%3A0x3a7ffb625d893472!2sAUTO%20PARTS%20LAMELA%201!5e0!3m2!1sen!2smk!4v1658871410258!5m2!1sen!2smk"
                        width={`${matches ? '300px' : '500px'}`}
                        height="450"
                        style={{ border: 0 }}
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"></iframe>
                </Box>
            </Box>
        </Box>
        <Box>
            <Typography variant="h4" mb={5} component="h4" color="#5b5b5b" fontWeight="900">Работно време:</Typography>
            <Box minWidth="300px" maxWidth="500px">
                <Box display="flex" justifyContent="space-between">
                    <Typography>Понеделник до Петок:</Typography>
                    <Typography> 09:00 до 18:00</Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                    <Typography>Сабота:</Typography>
                    <Typography>09:00 до 17:00</Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                    <Typography>Недела:</Typography>
                    <Typography>неработен</Typography>
                </Box>
            </Box>
        </Box>
    </Box>
}
export default Contact;