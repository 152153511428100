import { Box, Typography, useMediaQuery } from '@mui/material';

const AboutUs = () => {
    const matches = useMediaQuery('(max-width:700px)');
    return <Box p={`${matches ? '40px' : '100px'}`} id="aboutUs">
        <Typography variant="h4" component="h4" color="#5b5b5b" fontWeight="900" >За Нас</Typography>
        <Typography varint="p" mt={4} color="#c3c3c3" fontSize="20px" lineHeight="2" component="p">
            Ламела Дооел Тетово како компанија која продава авто делови од 06 ноември 2002 година, е цврсто позиционирана на врвот меѓу својата конкуренција со услуга и квалитет.
            Од самиот почеток, нашиот фокус е кон Вас купувачите – нашите најголеми и највредни партнери.
            <br />
            <br />
            Со нашата втора локација веќе од 29 ноември 2018 година, ја проширивме нашата галантерија и персонал, и сега сме повеќе од било кога достапни и 
            спремни да Ви помогнеме на било кој начин, од производи и совети па се до услуги и препораки до наши партнери.
        </Typography>
        <Box mt={4}>
            <Typography varint="h6" color="#5b5b5b" fontSize="25px" component="h6">Соработници</Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                <img src="./schaefflerGroup.png" alt="schaefflerGroup" />
                <img alt="partner_denckermann" src="./denckermann.png" />
                <img alt="partner_bosch" src="./bosch.png" />
                <img alt="partner_filtron" src="./filtron.png" />
                <img alt="partner_ate" src="./ate.png" />
                <img alt="parnter_trw" src="./trw.png" />
                <img alt="partner_victorReinz" src="./victorReinz.png" />
            </Box>
        </Box>
    </Box >
}
export default AboutUs;